/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  validateTokenOtpStart,
  validateTokenOtpSuccess,
  validateTokenOtpError,
  clearValidateTokenOtpState
} = createActions({
  VALIDATE_TOKEN_OTP_START: () => {},
  VALIDATE_TOKEN_OTP_SUCCESS: data => ({ data }),
  VALIDATE_TOKEN_OTP_ERROR: error => ({ error }),
  CLEAR_VALIDATE_TOKEN_OTP_STATE: () => {}
})

const validateTokenOTP = (membershipCode, token) => async dispatch => {
  dispatch(validateTokenOtpStart())
  try {
    const response = await api.post(`${apiRoutes.VALIDATE_TOKEN_OTP(membershipCode, token)}`)
    dispatch(validateTokenOtpSuccess({ status: response.status }))
  } catch (error) {
    dispatch(validateTokenOtpError(error.response ? error.response.data : error))
  }
}

export {
  validateTokenOTP,
  validateTokenOtpStart,
  validateTokenOtpSuccess,
  validateTokenOtpError,
  clearValidateTokenOtpState
}
