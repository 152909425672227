/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { apiSublos } from 'services/api'

const { fetchSublosRulesStart, fetchSublosRulesError, fetchSublosRulesSuccess } = createActions({
  FETCH_SUBLOS_RULES_START: () => {},
  FETCH_SUBLOS_RULES_SUCCESS: data => ({ data }),
  FETCH_SUBLOS_RULES_ERROR: error => ({ error })
})

const fetchSublosRules = ({ accountCode }) => {
  return async dispatch => {
    dispatch(fetchSublosRulesStart())

    try {
      const response = await apiSublos.get(
        apiRoutes.SUBLOS_BLACKOUT_RULES({
          accountCode
        })
      )

      dispatch(
        fetchSublosRulesSuccess({
          values: response.data.resources,
          pagination: response.data.pagination
        })
      )
    } catch (error) {
      dispatch(fetchSublosRulesError(error))
    }
  }
}

export { fetchSublosRules, fetchSublosRulesStart, fetchSublosRulesSuccess, fetchSublosRulesError }
