/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  resendEmailStart,
  resendEmailSuccess,
  resendEmailError,
  clearResendEmailState
} = createActions({
  RESEND_EMAIL_START: () => {},
  RESEND_EMAIL_SUCCESS: data => ({ data }),
  RESEND_EMAIL_ERROR: error => ({ error }),
  CLEAR_RESEND_EMAIL_STATE: () => {}
})

const resendEmail = email => async dispatch => {
  dispatch(resendEmailStart())
  try {
    const response = await api.post(apiRoutes.AR_PLUS_RESEND_EMAIL(email))
    dispatch(resendEmailSuccess(response.data))
  } catch (error) {
    dispatch(resendEmailError(error.response ? error.response.data : error))
  }
}

const resendOTPEmail = membershipCode => async dispatch => {
  dispatch(resendEmailStart())
  try {
    const response = await api.post(apiRoutes.AR_PLUS_OTP_RESEND_EMAIL(membershipCode))
    dispatch(resendEmailSuccess(response.data))
  } catch (error) {
    dispatch(resendEmailError(error.response ? error.response.data : error))
  }
}

export {
  resendEmail,
  resendOTPEmail,
  resendEmailStart,
  resendEmailSuccess,
  resendEmailError,
  clearResendEmailState
}
