import { handleActions } from 'redux-actions'

import {
  resendEmailStart,
  resendEmailSuccess,
  resendEmailError,
  clearResendEmailState
} from 'actions/arplus/resend-email'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const resendEmail = handleActions(
  {
    [resendEmailStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [resendEmailSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [resendEmailError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearResendEmailState]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default resendEmail
