import { htmlTags } from './validations'

export const removeHtmlTags = str => str.replace(htmlTags, '')

export const splitString = (toSplitString, splitChar) =>
  toSplitString
    .split(splitChar)
    .map(item => item.trim())
    .filter(item => item)

export const replaceSpacesWithSymbols = (inputString, symbol) => inputString?.replace(/\s/g, symbol)

export const replaceSymbolsWithSpaces = (inputString, symbol) =>
  inputString?.replace(new RegExp(symbol, 'g'), ' ')

export const normalizeSpaces = (string, separator = '') =>
  string
    .trim()
    .split(' ')
    .filter(item => Boolean(item))
    .join(separator)
