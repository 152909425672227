export default {
  'booking.exchanges.search-flights': 'Buscar vuelos',
  'booking.exchanges.dates.title': 'Solicitud de cambio de vuelo',
  'booking.exchanges.dates.title-mobile': 'Cambio de vuelo',
  'booking.exchanges.dates.changes-will-affect-all-passengers':
    'IMPORTANTE: Los cambios que realices afectarán a todos los pasajeros de la reserva. Si necesitás hacer el cambio únicamente para alguno de ellos y compraste el ticket en una agencia de viajes, contactate con ellos para realizar la gestión. Si lo compraste en Aerolíneas Argentinas, comunicate con nuestro Contact Center Argentina al 54 (11) 5199 3555 o a través de nuestros canales de atención (aerolineas.com.ar/canales-de-atencion). El nuevo ticket, producto del cambio que realizaste, mantendrá SIEMPRE la misma familia de tarifa / clase que la original (con las mismas condiciones de cambio, devolución y franquicia de equipaje) INDISTINTAMENTE del tipo de tarifa / clase que selecciones al momento de hacer el cambio. Si habías solicitado servicios especiales (comidas especiales, silla de ruedas, etc) para tu viaje, después de realizar tu cambio, es necesario que nos contactes, para que dicho servicio sea vinculado a tu nuevo vuelo. Tipos de cambios que NO podrás autogestionar: Cambios en tickets abiertos (tickets que no están relacionados con ninguna reserva activa). Cambios de rutas. Cambios de tickets Aerolíneas Plus emitidos en clase Promocional (P), Premium Economy (Z) o Business (O). Cambios en tickets de grupos. Cambios en tickets con vuelos operados por otras líneas aéreas. Cambios en tickets con múltiples destinos. Cambio de tickets con fechas pasadas. Ante estos casos o cualquier inconveniente que surja durante el proceso de cambio, te solicitamos: Si el ticket lo compraste en una agencia de viajes, contactate con la misma para realizar cualquier gestión.',
  'booking.exchanges.dates.remember':
    'Tipo de cambio permitidos desde la web:  Cambios de tickets emitidos en Argentina por medio de nuestra Web, Call Center o Agencias de Viaje.  Cambios "Flex", sin penalidad abonando diferencia de tarifa si la hubiera, siempre que hayas comprado tu ticket entre el 2 y el 15 de julio 2021 y cumplas con las condiciones de nuestra Política Flex.  Cambios de fecha.  Cambios involuntarios. Si tu vuelo fue reprogramado por más de 4horas, vas a poder reubicarte sin cargo en vuelos programados hasta 15 días antes o después de tu vuelo original. El cambio será sin cargo siempre que respetes la misma cabina, familia de tarifas (brand fare) y estadía máxima del ticket original. Importante: Para este tipo de cambios deberás respetar siempre el aeropuerto de salida y de llegada original del ticket.   Por ejemplo: si tu vuelo partía desde Aeroparque, al seleccionar el nuevo vuelo tenés que seleccionar origen Aeroparque, no podrás modificarlo sin costo por un vuelo desde Ezeiza',
  'booking.exchanges.dates.change-calling-to':
    'De haberlo adquirido directamente con nosotros comunicate con nuestro Call Center Argentina al 54 (11) 5199 3555 Lun a Vie de 8 a 20hs. Sáb, Dom y feriados de 8 a 14 hs. ó a través de nuestros canales de atención (aerolineas.com.ar/canales-de-atencion).',
  'booking.exchanges.dates.change-flight-date': 'Cambiar mi fecha de vuelo',
  'booking.exchanges.keep-reservation': 'Conservar reserva original',
  'booking.exchanges.keep-reservation-modal.you-will-change-flight-date':
    'Al querer conservar tu reserva original, cancelarás el cambio de vuelo. ¿Estás seguro?',
  'booking.exchanges.offers.edit-dates': 'Editar fechas',
  'booking.exchanges.error.main-button': 'Mis resultados',
  'booking.exchanges.error.secondary-button': 'Ir a mi reserva',
  'booking.exchanges.error.message': 'No se pudo realizar tu compra',
  'booking.exchanges.assist-card.alert-message':
    'Si tenés una asistencia de viaje contratada, asegurate de modificar las fechas contactándote con Assist Card por vía telefónica (<a href="tel:0800-288-2000" style="color:#396fa5">0800-288-2000</a>) o por correo electrónico (<a href="mailto:atencionalcliente@assistcard.com" style="color:#396fa5">atencionalcliente@assistcard.com</a>). Si habías solicitado servicios especiales (comidas especiales, silla de ruedas) y/o comprado una valija adicional, equipaje especial o Salón VIP para tu viaje, después de realizar tu cambio, es necesario que nos contactes, para que dicho servicio sea vinculado a tu nuevo vuelo.',
  'booking.exchanges.assist-card.modal-message':
    'Recordá actualizar las fechas de cobertura de tu seguro de viaje Assist card contactándote por vía telefónica (<a href="tel:{{unformattedPhoneNumber}}" style="color: #396FA5">{{formattedPhoneNumber}}</a>) o por correo electrónico (<a href="mailto:{{email}}" style="color: #396FA5">{{email}}</a>).',
  'booking.exchanges.new-itinerary.original-flight': 'Tu vuelo original',
  'booking.exchanges.new-itinerary.new-flight': 'Tu nuevo itinerario',
  'booking.exchanges.new-itinerary.confirm-change': 'Confirmar cambio',
  'booking.exchanges.fares.difference': 'Diferencia de tarifa',
  'booking.exchanges.fares.change-fee': 'Cargo de gestión',
  'booking.exchanges.dates.outbound-cannot-be-after-inbound':
    'La fecha de ida no puede ser posterior a la fecha de vuelta',
  'booking.exchanges.dates.read-conditions': 'Leer condiciones',
  'booking.exchanges.info-message.change-fee-waiver':
    'Algunos vuelos de despliegue tienen permitido exceptuar el cobro del Cargo de Gestión por este cambio. Para mayor detalle consulte nuestra <a href="https://www.aerolineas.com.ar/es-ar/landingsespeciales/landings/409_informacion-sobre-cambios-y-devoluciones" target="_blank">política de cambios</a>.',
  'booking.exchanges.info-message.price-difference-waiver':
    'Algunos vuelos del despliegue tienen permitido exceptuar el cobro del Cargo de Gestión y la Diferencia de Tarifa por este cambio. Para mayor detalle consulte nuestra <a href="https://www.aerolineas.com.ar/es-ar/landingsespeciales/landings/409_informacion-sobre-cambios-y-devoluciones" target="_blank">política de cambios</a>.',
  'booking.error.invalid-flight-type':
    'No se pudo realizar tu compra / cambio. Podés volver a tus resultados o realizar una nueva búsqueda.',
  'gds.booking.error.containsCheckedCoupons':
    'Si ya hiciste el checkin y aún no volaste, deberás comunicarte con nuestro call center para efectuar el cambio. De lo contrario, podrás realizar el cambio luego de haber volado el tramo actualmente chequeado.'
}
