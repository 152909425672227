import { handleActions } from 'redux-actions'

import {
  fetchSublosRulesStart,
  fetchSublosRulesSuccess,
  fetchSublosRulesError
} from 'actions/sublos/blackoutDatesRules'

const defaultSublosRulesState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false
}

const blackoutDates = handleActions(
  {
    [fetchSublosRulesStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchSublosRulesSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      isFetching: false,
      error: null
    }),
    [fetchSublosRulesError]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload
    })
  },
  defaultSublosRulesState
)

export default blackoutDates
