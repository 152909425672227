export const onlyString = /^[a-zA-Z\s]*$/

export const containsLetters = /[a-zA-Z]/

export const documentNumber = /^[0-9]{7,8}$/

export const validationNumberArPlus = /^[0-9]{7,8}$/

export const codArea = /^[0-9]{2,4}$/

export const onlyNumbers = /^[0-9]*$/

export const onlyNumbersPositive = /^[1-9]\d*$/

export const onlyNumbersPositiveMultipleOfTen = /^[1-9]\d*0$/

export const onlyStringSpecialCharacters = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/

export const alphanumeric = /^[a-zA-Z0-9]+$/

export const alphanumericOrEmpty = /^[0-9a-zA-Z]*$/

export const alphanumericAndSpace = /^[a-zA-Z0-9\s]+$/

export const fullAlphabetic = /^[a-zA-ZÀ-ÖØ-öø-ÿ\-\s]+$/

export const fullAlphabeticWithNumbers = /^[a-zA-ZÀ-ÖØ-öø-ÿ0-9\-\s]+$/

export const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const emailNoSpecialCharacters = /^(([A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const pnr = /^[A-Z0-9]{6}$/

export const addressStreet = /^[.0-9a-zA-ZÀ-ÖØ-öø-ÿ\-\s]*$/

export const addressCity = /^[.0-9a-zA-ZÀ-ÖØ-öø-ÿ\-\s]*$/

export const addressZipcode = /^[0-9A-Za-z-]*$/

export const addressState = /^[.0-9a-zA-ZÀ-ÖØ-öø-ÿ\-\s]*$/

export const addressBRStateCity = /^[,.a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/

export const addressBRstreet = /^[,.0-9a-zA-ZÀ-ÖØ-öø-ÿ\s]{1,25}|$/

export const promoCode = /^[a-zA-Z0-9]{3,20}$/

export const absoluteUrl = /^(http|https):\/\//

export const languageTag = /^[a-zA-Z]{2}-[a-zA-Z]{2}$/

export const currencyAmountDotSeparator = /^([0-9])+(\.[0-9]{1,2})?$/

export const cuit = /^(20|23|24|27|30|33|34)[0-9]{8,9}$/

export const cuil = /^(20|23|24|27)[0-9]{8,9}$/

export const cuitTypeOfPerson = /^[0-9]{2}$/

export const cuitLastAleatoryNumber = /^[0-9]{1}$/

export const nit = /^[0-9]{6,12}$/

export const ruc = /^[0-9]{1,11}$/

export const cbu = /^[0-9]{22}$/

export const dni = /^[1-9]{1,2}\.?\d{3}\.?\d{3}$/

export const vr = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)mpembed.com/

export const queryParam = /^(\/\?){1}[a-zA-Z]=[a-zA-Z]/

export const htmlTags = /(<([^>]+)>)/gi

export const refundPnr = /^[A-Za-z]{6}$/

export const refundVcr = /(^(044)[0-9]{10})(\/044[0-9]{10})*$/gm

export const multipleRefundVcr = /(^[0-9]{13})(\/[0-9]{13})*$/gm

export const refundUsaVcr = /([0-9]{13})$/

export const refundId = /^[0-9]{10}$/

export const vcrDocument = /^(044)[0-9]{10}$/

export const plusPartnerNumber = /^[0-9]{8}$/

export const refundPhone = /^[0-9]{6,}$/

export const refundPassangers = /^[a-zA-Z//\s]*$/

export const phoneNumber = /^[0-9]{5,9}$/

export const phoneNumberWithPlus = /^\+?[0-9]{10,25}$/

export const contactWhatsapp = /^[0-9]{1,50}$/

export const passwordPattern = /^[a-zA-z0-9-_.@]{6,8}$/

export const pnrRegex = /\b[A-Z]{6}\b/

export const alphanumericReplacementFormat = /[^a-zA-Z0-9]/g

export const alphanumericAndUnderscore = /^[a-zA-ZÀ-ÖØ-öø-ÿ0-9_\-\s]+$/

export const alphaNumericDotDashUnderscore = /^[a-zA-Z0-9._\-\s]+$/

export const validateField = (regex, value) => {
  return regex.test(value)
}

export const isQueryParam = value => validateField(queryParam, value)

export const isAbsoluteUrl = value => validateField(absoluteUrl, value)

export const onlyNumbersValidation = event => {
  if (!event.key.match(onlyNumbers)) {
    event.preventDefault()
  }
}
