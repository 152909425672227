/* eslint-disable import/no-cycle */
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'

import FORMATS from 'constants/formats'
import timeZones from 'constants/timeZones'
import { TRIP_TYPES } from 'constants/tripTypes'
import { getWeekdaysAsArray, getMonthsAsArray } from 'helpers/translations'

dayjs.extend(utc)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(localizedFormat)
dayjs.extend(duration)
dayjs.extend(customParseFormat)

export const getDayJs = date => dayjs(date)
export const getDayJsObject = date => dayjs.utc(date)
export const getDayJsObjectCustomFormat = (date, format) => dayjs(date, format)
export const getDayJsObjectFromLocal = date => dayjs(date).utc()
export const getDayJsObjectFromLocalWithTimezone = (date, timezone = timeZones.BUENOS_AIRES) =>
  !date.includes('Z')
    ? getDayJsObjectFromLocal(date)
    : dayjs(new Date(date).toLocaleString('en-US', { timeZone: timezone })).utc()

export const getDay = date => {
  const parsedDate = getDayJsObject(date)

  return parsedDate.date()
}

export const getMonthName = date => {
  const parsedDate = getDayJsObject(date)
  const months = getMonthsAsArray()

  return months[parsedDate.month()]
}

export const getYear = date => {
  const parsedDate = getDayJsObject(date)

  return parsedDate.year()
}

export const getFullDate = date => {
  const parsedDate = getDayJsObject(date)

  const dayName = parsedDate.format(FORMATS.DAY_NAME)

  const monthYear = parsedDate.format(FORMATS.MONTH_YEAR_NAME)

  return { dayName, monthYear }
}

export const getDayName = date => {
  const parsedDate = getDayJsObject(date)
  const dayOfWeek = getWeekdaysAsArray()

  return dayOfWeek[parsedDate.day()]
}

export const isSameDate = (first, second) => {
  const parsedFirstDate = getDayJsObject(first)
  const parsedSecondDate = getDayJsObject(second)

  return parsedFirstDate.isSame(parsedSecondDate)
}

export const getDateWithFormat = (date, format = FORMATS.DATE) => {
  return dayjs(date).format(format)
}

export const getHourFromDate = date => getDateWithFormat(date, FORMATS.HOUR_AND_MINUTES)

export const getMonth = getDayJsObject().month() + 1

export const getMonthToday = (getMonth < 10 && '0') + getMonth

export const getYearToday = getDayJsObject().year()

export const getDateDifferenceInYears = (first, second) => {
  return getDayJsObject(first).diff(getDayJsObject(second), 'year')
}

export const addMonthsFromToday = monthAmount => {
  return getDayJsObject().add(monthAmount, 'month')
}

export const addDaysFromToday = dayAmount => {
  return getDayJsObject().add(dayAmount, 'day')
}

export const addDaysFromDate = (startDate, dayAmount) => {
  const startDateObject = dayjs(startDate)
  return startDateObject.add(dayAmount, 'day')
}

export const setUTCHours = date => {
  return date.setUTCHours(12)
}

export const isSameMonthAndYear = (first, second) => {
  return (
    first &&
    second &&
    first.getMonth() === second.getMonth() &&
    first.getFullYear() === second.getFullYear()
  )
}

// Returns if a date is valid or not.
export const isDateProhibited = (tripType, date, minDate, maxDate) => {
  if (tripType === TRIP_TYPES.RETURN) {
    return getDayJsObject(date).isBefore(getDayJsObject(minDate))
  } else if (tripType === TRIP_TYPES.ONE_WAY) {
    return getDayJsObject(date).isAfter(getDayJsObject(maxDate))
  }
  return false
}

// Check if both dates are equal, this method does't take into account
// hours, minutes and seconds.
export const isDateEqual = (comparedDate, date) =>
  dayjs(comparedDate)
    .startOf('day')
    .isSame(dayjs(date).startOf('day'))

const getLocalDate = date => getDayJsObject(date).local()

const getLocalDateWithFormat = (date, format = FORMATS.DATE) => getLocalDate(date).format(format)

export const getLocalDateFormatted = date =>
  getLocalDateWithFormat(getDayJsObject(date).local(), FORMATS.DAY_MONTH_YEAR)

export const getLocalTimeFormatted = date =>
  getLocalDateWithFormat(getDayJsObject(date).local(), FORMATS.HOUR_AND_MINUTES)

export const getFormattedDate = (date, format = FORMATS.ISO_WITHOUT_MILLISECONDS) =>
  getDayJsObject(date).format(format)

export const belongsToRange = (day, minDate, maxDate) => {
  const dateToCheck = getDayJsObject(day)
  const earliestDate = getDayJsObject(minDate)
  const latestDate = getDayJsObject(maxDate)

  return dateToCheck.isSameOrAfter(earliestDate) && dateToCheck.isSameOrBefore(latestDate)
}

export const isBeforeToday = someDate => getDayJsObject(someDate).isBefore(getDayJsObject())

export const getPreviousDayFromLocalAsDate = () =>
  getDayJsObjectFromLocal()
    .subtract(1, 'day')
    .toDate()

export const getTodayIfIsPastDate = date => {
  const today = getDayJsObjectFromLocal()
  if (date < today) {
    return today
  }
  return date
}

export const getDateWithLocalizedFormat = date => dayjs.utc(date).format(FORMATS.LOCALIZED)

export const getLastNYearsPlusOne = nYears => {
  const currentYearPlusOne = dayjs().year() + 1
  return [...Array(nYears)].map((year, index) => ({
    label: currentYearPlusOne - index,
    value: currentYearPlusOne - index
  }))
}

export const getLastNYearsDate = n => {
  const myDate = new Date()
  myDate.setFullYear(myDate.getFullYear() - n)
  return myDate
}

/* Chrome Dates */
export const todayToDate = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1
  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }

  return yyyy + '-' + mm + '-' + dd
}

export const getDurationTimeBetweenTwoDates = (firstDate, secondDate) => {
  const first = dayjs(firstDate)
  const second = dayjs(secondDate)
  const duration = dayjs.duration(second.diff(first))
  return `${duration.hours()}h ${duration.minutes()}m`
}
