const IconNames = {
  Aerolineas: 'Aerolineas',
  Account: 'Account',
  AccountBorderless: 'AccountBorderles',
  AddCircleOutline: 'AddCircleOutline',
  AerolineasPlus: 'AerolineasPlus',
  Airport: 'Airport',
  Airplane: 'Airplane',
  AirplaneLandingLeft: 'AirplaneLandingLeft',
  AirplaneLandingRight: 'AirplaneLandingRight',
  AirplaneRight: 'AirplaneRight',
  Alert: 'Alert',
  AutoRenew: 'AutoRenew',
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  Basket: 'Basket',
  Bell: 'Bell',
  BellOff: 'BellOff',
  Briefcase: 'Briefcase',
  Briefcases: 'Briefcases',
  BriefcaseCheck: 'BriefcaseCheck',
  Business: 'Business',
  CalendarClock: 'CalendarClock',
  Calendar: 'Calendar',
  Cellphone: 'Cellphone',
  Change: 'Change',
  ChatLeft: 'ChatLeft',
  Check: 'Check',
  Checkmark: 'Checkmark',
  ChevronDown: 'ChevronDown',
  ChevronLeft: 'ChevronLeft',
  ChevronRight: 'ChevronRight',
  ChevronUp: 'ChevronUp',
  ChildFriendly: 'ChildFriendly',
  ClipboardText: 'ClipboardText',
  ClockAlert: 'ClockAlert',
  Clock: 'Clock',
  ClockFast: 'ClockFast',
  Close: 'Close',
  Currency: 'Currency',
  Collection: 'Collection',
  Computer: 'Computer',
  CreditCard: 'CreditCard',
  CreditCardOff: 'CreditCardOff',
  CreditCardPlus: 'CreditCardPlus',
  CrossError: 'CrossError',
  ClockOutline: 'ClockOutline',
  Dialog: 'Dialog',
  DialogBalloonInfo: 'DialogBalloonInfo',
  DollarSign: 'DollarSign',
  DotsHorizontal: 'DotsHorizontal',
  Download: 'Download',
  Duration: 'Duration',
  Email: 'Email',
  EyeSettings: 'EyeSettings',
  EyeSettingsOutline: 'EyeSettingsOutline',
  Facebook: 'Facebook',
  Filter: 'Filter',
  FlightTakeOff: 'FlightTakeOff',
  FoodForkDrink: 'FoodForkDrink',
  FullCircleCheck: 'FullCircleCheck',
  FullCircleClose: 'FullCircleClose',
  MoreInfoFrequentFlyer: 'MoreInfoFrequentFlyer',
  MoreInfoTicket: 'MoreInfoTicket',
  Gift: 'Gift',
  Google: 'Google',
  Headset: 'Headset',
  HelpCircle: 'HelpCircle',
  Help: 'Help',
  HumanMale: 'HumanMale',
  HumanMaleFemale: 'HumanMaleFemale',
  Ida: 'Ida',
  Info: 'Info',
  Instagram: 'Instagram',
  Language: 'Language',
  LibraryAdd: 'LibraryAdd',
  Linkedin: 'Linkedin',
  LockOpen: 'LockOpen',
  Luggage: 'Luggage',
  SpecialBags: 'SpecialBags',
  Map: 'Map',
  Menu: 'Menu',
  Minus: 'Minus',
  MultiCreditCard: 'MultiCreditCard',
  NotebookCheck: 'NotebookCheck',
  OrderBy: 'OrderBy',
  PassengerSeated: 'PassengerSeated',
  People: 'People',
  PdfFile: 'PdfFile',
  PinDrop: 'PinDrop',
  PlainSeat: 'PlainSeat',
  Plus: 'Plus',
  Reload: 'Reload',
  RemoveCircleOutline: 'RemoveCircleOutline',
  Return: 'Return',
  Scale: 'Scale',
  Seat: 'Seat',
  Search: 'Search',
  Settings: 'Settings',
  Share: 'Share',
  ShoppingCart: 'ShoppingCart',
  StarShape: 'StarShape',
  SwapHorizontalCircle: 'SwapHorizontalCircle',
  TagMultiple: 'TagMultiple',
  TagPlus: 'TagPlus',
  Ticket: 'Ticket',
  TicketPercent: 'TicketPercent',
  TicketStar: 'TicketStar',
  VipLounge: 'VipLounge',
  Twitter: 'Twitter',
  WalletTravel: 'WalletTravel',
  Youtube: 'Youtube',
  ARPlusCard: 'ARPlusCard',
  PaperClip: 'PaperClip',
  WalletGrey: 'WalletGrey',
  Link: 'Link',
  ToDo: 'ToDo',
  Receipt: 'Receipt',
  Local_hospital: 'Local_hospital',
  DownloadOutline: 'DownloadOutline',
  A: 'box-a',
  AccountBalance: 'AccountBalance',
  AccountBalanceWallet: 'AccountBalanceWallet',
  AirlineSeatRecline: 'AirlineSeatRecline',
  AirplaneFlying: 'AirplaneFlying',
  ArrowLeftRight: 'ArrowLeftRight',
  ArrowUp: 'ArrowUp',
  Assignment: 'Assignment',
  AttachMoney: 'AttachMoney',
  Block: 'Block',
  BusinessCenter: 'BusinessCenter',
  Cancelled: 'Cancelled',
  Cached: 'Cached',
  CalendarToday: 'CalendarToday',
  Clear: 'Clear',
  Cloud_upload: 'Cloud_upload',
  CloseCircle: 'CloseCircle',
  CloseCircleNonFill: 'CloseCircleNonFill',
  Company: 'Company',
  Competence: 'Competence',
  Copy: 'Copy',
  Delete: 'Delete',
  Description: 'Description',
  DotsVertical: 'DotsVertical',
  Done: 'Done',
  FilterList: 'FilterList',
  FormatListNumeric: 'FormatListNumeric',
  Keyboard_arrow_down: 'Keyboard_arrow_down',
  Keyboard_arrow_up: 'Keyboard_arrow_up',
  ListBulleted: 'ListBulleted',
  ListBoxes: 'List-boxes',
  List: 'List',
  LocalActivity: 'LocalActivity',
  Location: 'Location',
  Lock: 'Lock',
  Navigation: 'Navigation',
  Pencil: 'Pencil',
  Pound: 'Pound',
  PlusCircle: 'PlusCircle',
  Security: 'Security',
  SignIn: 'SignIn',
  SortVariant: 'SortVariant',
  StarComment: 'StarComment',
  Tag: 'Tag',
  Translate: 'Translate',
  Upload: 'Upload',
  Web: 'Web',
  Work: 'Work',
  Encrypted: 'encrypted'
}

export default IconNames
