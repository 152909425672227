/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'

import GDS_MESSAGES from 'constants/gdsMessages'
import { TRIP_TYPES } from 'constants/tripTypes'
import { mapFareOptionConfiguration } from 'helpers/flightConditions'
import { isEmpty } from 'helpers/utils'
import { getBrands } from 'selectors/brands'
const getFlightsOffers = state => state.flightsOffers

export const getFlightsOffersData = createSelector([getFlightsOffers], flightOffers =>
  !isEmpty(flightOffers) ? flightOffers.data : null
)

export const getFlightsOffersAlternateOffers = createSelector(
  [getFlightsOffersData],
  flightsOffers => (!isEmpty(flightsOffers) ? flightsOffers.alternateOffers : null)
)

export const getFlightsOffersMetadata = createSelector([getFlightsOffersData], flightsOffers =>
  !isEmpty(flightsOffers) ? flightsOffers.searchMetadata : null
)

/** Use this selector to find out if the calendar in /flex-dates-calendar is calendar30 or flexCalendar Type
 * This can be set from selecting the type associated with the channel in BO: /catalog/channels
 */
export const getFlightsOffersSearchType = createSelector(
  [getFlightsOffersMetadata],
  flightsOffersMetadata =>
    !isEmpty(flightsOffersMetadata) ? flightsOffersMetadata.searchType : null
)

export const getFlightsOffersFlightType = createSelector([getFlightsOffersMetadata], metadata =>
  !isEmpty(metadata) ? metadata.flightType : ''
)

export const getDiscountsApplied = createSelector([getFlightsOffersMetadata], metadata =>
  !isEmpty(metadata) ? metadata.discountsApplied : false
)

export const getFlightsOffersCurrency = createSelector([getFlightsOffersMetadata], metadata =>
  !isEmpty(metadata) ? metadata.currency : ''
)

export const getFlightsOffersProgramId = createSelector([getFlightsOffersMetadata], metadata =>
  !isEmpty(metadata) ? metadata.programId : ''
)

export const getFlightsCalendarsOffers = createSelector([getFlightsOffersData], flightsOffers =>
  !isEmpty(flightsOffers) ? flightsOffers.calendarOffers : null
)

const mapOffers = (type, offers) =>
  offers
    ? offers.map(({ departure, leg, bestOffer, userSelection, offerDetails }) => {
      const mappedOffer = {
        type,
        date: departure,
        bestOffer,
        userSelection,
        emptyOffer: true
      }

      // If there isn't any flights offers available for departure date
      // the leg property will be empty, if not add leg data to mapped offer.
      if (leg) {
        const [firstSegment] = leg.segments

        return {
          ...mappedOffer,
          discounted: offerDetails.discounted,
          total: offerDetails.fare.total,
          origin: firstSegment.origin,
          destination: firstSegment.destination,
          emptyOffer: false
        }
      }

      return mappedOffer
    })
    : []

// Map flights offers to facilitate manipulation.
export const mapFlightsOffers = createSelector([getFlightsCalendarsOffers], calendarOffers => {
  if (calendarOffers) {
    const [oneWayTripOffers, returnTripOffers] = Object.values(calendarOffers)

    return {
      [TRIP_TYPES.ONE_WAY]: mapOffers(TRIP_TYPES.ONE_WAY, oneWayTripOffers),
      [TRIP_TYPES.RETURN]: returnTripOffers ? mapOffers(TRIP_TYPES.RETURN, returnTripOffers) : null
    }
  }
})

const mapBrandedOffers = (brandedOffers, flightType, isExchanges) =>
  brandedOffers.map(({ legs, offers, bestOffer }) => {
    return {
      legs: legs.map(({ segments, stops, totalDuration, connectionsInformation }) => ({
        segments,
        origin: segments[0].origin,
        departure: segments[0].departure,
        destination: segments[segments.length - 1].destination,
        arrival: segments[segments.length - 1].arrival,
        stops,
        totalDuration,
        airline: segments[0].airline,
        flightNumber: segments[0].flightNumber,
        operatingAirline: segments[0].operatingAirline,
        bookingClass: offers[0].bookingClass,
        connectionsInformation
      })),
      offers:
        // if it is round trip, and only one leg is requested to be exchanged
        // the combinable offer condition mustn't be checked
        flightType === TRIP_TYPES.ROUND_TRIP &&
          !(isExchanges && legs.length === 1) &&
          offers.every(offer => isEmpty(offer.combinableOffers))
          ? null
          : offers.map(
            ({ brand, offerId, fare, seatAvailability, combinableOffers, discounted }) => ({
              brandId: brand.id,
              offerId: offerId,
              brandName: brand.name,
              combinableOffers: combinableOffers,
              fare: fare.total,
              seats: seatAvailability.seats,
              lowAvailability: seatAvailability.lowAvailability,
              discounted
            })
          ),
      bestOffer
    }
  })

export const getBrandedOffers = createSelector([getFlightsOffersData], flightOffers =>
  flightOffers ? flightOffers.brandedOffers : []
)

const getExchangesFromProp = (_, props) => (props ? props.isExchanges : false)

// Map offers to facilitate manipulation.
export const mapBrandedFlightsOffers = createSelector(
  [getBrandedOffers, getFlightsOffersMetadata, getExchangesFromProp],
  (offers, metadata, isExchanges) => {
    if (offers && metadata) {
      const [oneWayTripOffers, returnTripOffers] = Object.values(offers)
      return {
        [TRIP_TYPES.ONE_WAY]: oneWayTripOffers
          ? mapBrandedOffers(oneWayTripOffers, metadata.flightType, isExchanges)
          : null,
        [TRIP_TYPES.RETURN]: returnTripOffers
          ? mapBrandedOffers(returnTripOffers, metadata.flightType, isExchanges)
          : null
      }
    }

    return {
      [TRIP_TYPES.ONE_WAY]: null,
      [TRIP_TYPES.RETURN]: null
    }
  }
)

//TODO: Improve this
export const getFareFamilies = createSelector(
  info => info,
  info => {
    const { fareFamilies, brands } = info

    if (!isEmpty(fareFamilies) && !isEmpty(brands)) {
      return fareFamilies
        .map(fareFamily => brands.find(({ code }) => code === fareFamily.id))
        .filter(el => el)
    }

    return []
  }
)

export const getSortingOptions = createSelector([getFlightsOffersData], flightOffers => {
  if (!isEmpty(flightOffers)) {
    return (flightOffers.sortingOptions || []).map(({ code, label }) => ({
      value: code,
      label
    }))
  }

  return []
})

export const getFacets = createSelector([getFlightsOffersData], flightOffers =>
  flightOffers ? flightOffers.facets : []
)

export const getFlightItinerary = createSelector(
  flightData => flightData,
  flightData => {
    const legs = flightData.departure

    if (!legs) {
      return {}
    }
    return {
      segments: legs.segments,
      connectionsInformation: legs.connectionsInformation,
      stops: legs.stops,
      totalDuration: legs.totalDuration,
      dateDiff: 0
    }
  }
)

export const getFlightsOffersShoppingId = createSelector([getFlightsOffersMetadata], metaData =>
  metaData ? metaData.shoppingId : ''
)

export const getFlightsOffersError = createSelector(
  [getFlightsOffers],
  flightOffers => flightOffers.error
)

export const isFetchingFlightsOffers = createSelector(
  [getFlightsOffers, getFlightsOffersData],
  (flightOffers, flightsOffersData) => flightOffers.isFetching || isEmpty(flightsOffersData)
)

export const getFareFamiliesData = createSelector([getFlightsOffersData], flightOffers =>
  flightOffers ? flightOffers.fareFamilies : []
)

export const getOffersBrands = createSelector([getFlightsOffersData], flightOffers => {
  if (!isEmpty(flightOffers)) {
    return (flightOffers.fareFamilies || []).map(({ id }) => id)
  }

  return []
})

export const getFlightsOffersIsFetching = createSelector(
  [getFlightsOffers],
  flightOffers => flightOffers && flightOffers.isFetching
)

export const getFlightsOffersInfoMessages = createSelector([getFlightsOffersMetadata], metadata =>
  metadata ? metadata.infoMessages : null
)

/*
 *  this message happens for round trip when there are no branded offers
 *  or one way trip without branded or alternate offers
 */
export const isEmptyFlightsOffers = createSelector(
  [getFlightsOffersInfoMessages],
  infoMessages =>
    infoMessages && infoMessages.find(msg => msg.includes('gds.flights.info.emptyResults'))
)

export const getFlightOffersFareRules = createSelector([getFlightsOffersData], flightOffers =>
  flightOffers ? flightOffers.fareRules : null
)

export const faresConditionsHeaders = createSelector([getFlightsOffersData], flightsOffersData => {
  if (
    !flightsOffersData?.fareRules?.[0]?.brandConfigurations ||
    !Array.isArray(flightsOffersData.fareRules) ||
    flightsOffersData.fareRules.length === 0
  ) {
    return []
  }

  const fareOptionConfiguration = Object.values(
    flightsOffersData.fareRules[0].brandConfigurations
  )?.[0]?.fareOptionConfiguration

  if (!fareOptionConfiguration) {
    return []
  }

  return mapFareOptionConfiguration(fareOptionConfiguration)
})

export const compareFares = createSelector(
  [
    getOffersBrands,
    getFlightOffersFareRules,
    getBrands,
    getFlightsOffersData,
    getFlightsOffersCurrency
  ],
  (brands, fares, fullBrands, flightsOffersData, currency) => {
    if (isEmpty(brands) || isEmpty(fares)) {
      return []
    }

    const validateFareFamilies = getFareFamilies({
      fareFamilies: flightsOffersData.fareFamilies,
      brands: fullBrands,
      currency: currency
    })

    const routeConditions = getRouteConditions(fares, brands)
    return routeConditions
      ? {
        route: routeConditions.route,
        conditions: (routeConditions.conditions || []).filter(condition =>
          (validateFareFamilies || []).find(fare => fare.active && fare.code === condition.id)
        )
      }
      : []
  }
)

const getRouteConditions = (fares, brands) => ({
  route: fares.displayName,
  conditions: getSortedConditions(fares, brands)
})

const getSortedConditions = (fares, brands) => {
  const sortedConditions = []

  brands.forEach(brand => {
    const fareOptionConfiguration =
      fares && fares[0].brandConfigurations[brand]?.fareOptionConfiguration

    if (fareOptionConfiguration) {
      sortedConditions.push({
        id: brand,
        fare: mapFareOptionConfiguration(fareOptionConfiguration)
      })
    }
  })

  return sortedConditions
}

export const getFlightOffersWarnMessage = createSelector([getFlightsOffersMetadata], flightOffers =>
  flightOffers ? flightOffers.warnMessages : null
)

export const isPromotionNotFoundWarning = createSelector([getFlightOffersWarnMessage], warnings =>
  warnings
    ? warnings.includes('gds.shopping.warn.invalid-campaign-fields') ||
    warnings.includes('gds.shopping.warn.invalid-campaign-field-patterns') ||
    warnings.includes('gds.shopping.warn.invalid-campaign-code') ||
    warnings.includes('gds.shopping.warn.invalid-promo-code')
    : null
)

export const getExchangesOffersEmptyLeg = createSelector([getBrandedOffers], offers => {
  if (offers) {
    const key = Object.keys(offers).find(key => isEmpty(offers[key]))
    if (key !== undefined) {
      return parseInt(key)
    }
  }

  return null
})

export const isOneLegExchangeOfRoundTrip = createSelector(
  [getBrandedOffers],
  offers => !isEmpty(offers) && Object.values(offers).some(value => isEmpty(value))
)

export const getFlightOffersMarket = createSelector(
  [getFlightsOffersMetadata],
  metadata => (metadata && metadata.market) || null
)

export const getExchangesInfoMessage = createSelector(
  [getFlightsOffersInfoMessages],
  infoMessages => {
    if (infoMessages) {
      if (infoMessages.includes(GDS_MESSAGES.EXCHANGES_CHANGE_FEE_WAIVER)) {
        return 'booking.exchanges.info-message.change-fee-waiver'
      }
      if (infoMessages.includes(GDS_MESSAGES.EXCHANGES_PRICE_DIFFERENCE_WAIVER)) {
        return 'booking.exchanges.info-message.price-difference-waiver'
      }
    }

    return null
  }
)

export const getExchangesErrorsInfoMessage = createSelector(
  [getFlightsOffersInfoMessages],
  infoMessages => {
    if (infoMessages) {
      if (infoMessages.includes(GDS_MESSAGES.EXCHANGES_PRICE_DIFFERENCE_WAIVER)) {
        return 'shopping.flight-offers.we-cannot-process-your-request.reacomm'
      }
    }

    return null
  }
)
