import { handleActions } from 'redux-actions'

import {
  validateTokenOtpStart,
  validateTokenOtpSuccess,
  validateTokenOtpError,
  clearValidateTokenOtpState
} from 'actions/arplus/validateTokenOTP'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const validateTokenOTP = handleActions(
  {
    [validateTokenOtpStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [validateTokenOtpSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [validateTokenOtpError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearValidateTokenOtpState]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default validateTokenOTP
