/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { LogOut } from 'helpers/flightOffers'
import { getLSValue } from 'helpers/localStorage'
import api from 'services/api'

const {
  transferMilesStart,
  transferMilesSuccess,
  transferMilesError,
  clearTransferMilesState
} = createActions({
  TRANSFER_MILES_START: () => {},
  TRANSFER_MILES_SUCCESS: data => ({ data }),
  TRANSFER_MILES_ERROR: error => ({ error }),
  CLEAR_TRANSFER_MILES_STATE: () => {}
})

const transferMiles = payload => async dispatch => {
  dispatch(transferMilesStart())
  try {
    const shoppingId = getLSValue('shoppingId')
    const response = await api.post(`${apiRoutes.TRANSFER_MILES_AR_PLUS}`, {
      shoppingId,
      ...payload
    })

    dispatch(transferMilesSuccess(response.data))
  } catch (error) {
    const err = error.response ? error.response.data : error

    if (err?.statusCode === 401) {
      LogOut()
      dispatch(clearTransferMilesState())
    }

    dispatch(transferMilesError(error.response ? error.response.data : error))
  }
}

export {
  transferMiles,
  transferMilesStart,
  transferMilesSuccess,
  transferMilesError,
  clearTransferMilesState
}
