import React from 'react'

import IconNames from './iconNames'
import Icons from './icons'
import { StyledIcon } from './styled'

const Icon = ({
  className,
  name,
  color = 'black',
  size,
  e2eClass,
  alt,
  role,
  ariaLabel,
  margin
}) => {
  if (!name || !Icons[name]) {
    return null
  }
  const { element, height = 24, width = 24, defaultMargin = 0 } = Icons[name]

  return (
    <StyledIcon
      className={className}
      iconColor={color}
      e2eClass={e2eClass}
      width={size || width}
      height={size || height}
      viewBox={'0 0 ' + width + ' ' + height}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      role={role}
      alt={alt}
      aria-label={ariaLabel}
      margin={margin || defaultMargin}
    >
      {element}
    </StyledIcon>
  )
}

export default Icon

export { IconNames, Icons }
